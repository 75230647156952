import axios from 'axios';

export const generateMCQs = async (securityKey, sessionParams) => {
  
  const API_URL = `https://bookboiapi-xnb4mfwe7q-uc.a.run.app/create_session?security_key=${(securityKey)}`;
  console.log(API_URL)

  try {
    
    const response = await axios.post(API_URL, sessionParams, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    return response.data;
  } catch (err) {
  
    throw new Error('Error generating MCQs: ' + err.message);
  }
};
