import React, { useState, useEffect } from 'react';
import FileUpload from './sections/FileUpload';
import QuestionDisplay from './sections/QuestionDisplay';
import { generateMCQs } from './sections/api';
import logo from './assets/LOGO.jpg';
import help_img from './assets/HELPIMG.jpg';
import './css/App.css';

const App = () => {
  const [mcqs, setMcqs] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState({ correct: 0, total: 0 });
  const [sessions, setSessions] = useState([]);
  const [showHelpImage, setShowHelpImage] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  useEffect(() => {
    const storedSessions = JSON.parse(localStorage.getItem('quizSessions')) || [];
    setSessions(storedSessions);
  }, []);

  useEffect(() => {
    if (sessions.length > 0) {
      localStorage.setItem('quizSessions', JSON.stringify(sessions));
    }
  }, [sessions]);

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => Math.min(prevIndex + 1, mcqs.length - 1));
  };

  const handlePreviousQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleUploadComplete = (response, fileName) => {
    setUploading(false);
    setGenerating(false);
    setMcqs(response.questions || []);
    setCurrentQuestionIndex(0);
    setScore({ correct: 0, total: 0 });

    const sessionData = {
      fileName,
      firstQuestion: response.questions[0]?.question || 'Untitled Session',
      questions: response.questions,
      score: score,
      totalQuestions: response.questions.length,
    };

    setSessions((prevSessions) => {
      const newSessions = [sessionData, ...prevSessions.slice(0, 2)];
      return newSessions;
    });
  };

  const handleUploadStart = async (fileName) => {
    setUploading(true);
    setGenerating(true);
    const response = await generateMCQs();
    handleUploadComplete(response, fileName);
  };

  const handleScoreUpdate = (isCorrect) => {
    setScore((prevScore) => ({
      correct: prevScore.correct + (isCorrect ? 1 : 0),
      total: prevScore.total + 1,
    }));
  };

  const handleSaveSession = () => {
    const sessionData = {
      questions: mcqs,
      score: score,
      totalQuestions: mcqs.length,
    };

    setSessions((prevSessions) => {
      const newSessions = [sessionData, ...prevSessions.slice(0, 2)];
      return newSessions;
    });
  };

  const restoreSession = (index) => {
    const sessionToRestore = sessions[index];
    setMcqs(sessionToRestore.questions);
    setCurrentQuestionIndex(0);
    setScore(sessionToRestore.score);
  };

  const handleHelpClick = () => {
    setShowHelpImage(true);
  };

  const closeHelpImage = () => {
    setShowHelpImage(false);
  };

  const handleFeedbackClick = () => {
    setShowFeedbackModal(true);
  };

  const closeFeedbackModal = () => {
    setShowFeedbackModal(false);
  };

  const handleFeedbackSubmit = () => {
    setFeedbackSubmitted(true);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <header className="navbar">
        <div className="navbar-left">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" style={{ width: '100px', height: 'auto', borderRadius: '15px' }} />
            <p className="logo-quote">LEARN FAST... QUIZ SMART</p>
          </div>
          <h1 className="app-title">QUATICKS</h1>
        </div>
        <nav className="navbar-right">
          <a href="/" className="nav-link">Home</a>
          <p className="res-link" onClick={handleFeedbackClick}>Feedback</p>
          <div className="help-button" onClick={handleHelpClick}>
            <span>?</span>
          </div>
        </nav>
      </header>

      <div style={{ display: 'flex', flex: 1, marginTop: '10px' }}>
        <div style={{ width: '30%', padding: '20px', borderRight: '1px solid #ddd', position: 'relative' }}>
          <FileUpload onUploadComplete={handleUploadComplete} onUploadStart={handleUploadStart} />
          <div style={{ marginTop: '20px' }}>
            <div style={{
              border: '2px solid #007bff',
              borderRadius: '8px',
              padding: '15px',
              marginBottom: '10px',
            }}>
              <h2 style={{
                color: '#007bff',
                textAlign: 'center',
                marginBottom: '10px',
              }}>Saved Sessions</h2>
              <div style={{ maxHeight: '150px', overflowY: 'auto', marginBottom: '10px' }}>
                {sessions.length === 0 ? (
                  <p>No saved sessions.</p>
                ) : (
                  sessions.map((session, index) => (
                    <div
                      key={index}
                      onClick={() => restoreSession(index)}
                      style={{
                        padding: '10px',
                        margin: '5px 0',
                        cursor: 'pointer',
                        backgroundColor: '#f9f9f9',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                      }}
                      title={session.fileName}
                    >
                      <strong>{index + 1}.</strong> {session.firstQuestion || 'Untitled Session'}
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          setSessions(sessions.filter((_, i) => i !== index));
                        }}
                        style={{
                          marginLeft: '10px',
                          background: 'red',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          cursor: 'pointer',
                        }}
                      >
                        X
                      </button>
                    </div>
                  ))
                )}
              </div>
              <p style={{ fontSize: '12px', color: '#888', textAlign: 'center' }}>
                Saved sessions can be viewed offline or after refreshing the page.
              </p>
            </div>
          </div>
        </div>

        <div style={{ width: '70%', padding: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {uploading || generating ? (
            <div style={{ width: '100%', textAlign: 'center' }}>
              {uploading && <p>Uploading...</p>}
              {generating && <p>Generating MCQs...</p>}
            </div>
          ) : mcqs.length > 0 ? (
            <QuestionDisplay
              question={mcqs[currentQuestionIndex]}
              currentIndex={currentQuestionIndex}
              totalQuestions={mcqs.length}
              onNext={handleNextQuestion}
              onPrevious={handlePreviousQuestion}
              onScoreUpdate={handleScoreUpdate}
              onSaveSession={handleSaveSession}
              score={score}
            />
          ) : (
            <p>No questions generated yet. Please upload files to start.</p>
          )}
        </div>
      </div>

      {showHelpImage && (
        <div className="help-image-modal" onClick={closeHelpImage}>
          <img src={help_img} alt="Help Logo" className="help-image" />
        </div>
      )}

      {showFeedbackModal && (
        <div className="feedback-modal">
          <div className="feedback-modal-content">
            <span className="close-button" onClick={closeFeedbackModal}>&times;</span>
            {!feedbackSubmitted ? (
              <>
                <iframe
                  src="https://docs.google.com/forms/d/e/1FAIpQLSfEz1UUhXYzlC2nuxXQijN5ZqIzeJVg5wJ93Wi3eQP9TLXQ4A/viewform?embedded=true"
                  width="640"
                  height="640"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  title="Feedback Form"
                  onLoad={handleFeedbackSubmit}
                >
                  Loading…
                </iframe>
              </>
            ) : (
              <div className="feedback-thankyou">
                <h2>Thank you for your feedback!</h2>
              </div>
            )}
          </div>
        </div>
      )}

      <footer style={{ padding: '20px', textAlign: 'center', backgroundColor: '#f9f9f9', borderTop: '1px solid #ddd' }}>
        <p>&copy; 2024 Quaticks. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
