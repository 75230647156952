import React, { useState, useEffect } from 'react';
import quotes from '../assets/quotes.json'; 

const LoadingModal = () => {
  const [quoteIndex, setQuoteIndex] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    
    const getRandomQuoteIndex = () => Math.floor(Math.random() * quotes.length);

   
    setQuoteIndex(getRandomQuoteIndex());

   
    const interval = setInterval(() => {
      setQuoteIndex(getRandomQuoteIndex());
      setProgress(0); // reset progress
    }, 10000); // 10 seconds , change if needed

    
    const progressInterval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          return 100; 
        }
        return prevProgress + 1;
      });
    }, 100); 

    
    return () => {
      clearInterval(interval);
      clearInterval(progressInterval);
    };
  }, []);

  return (
    <div style={modalStyle}>
      <div style={contentStyle}>
        <p style={quoteStyle}>"{quotes[quoteIndex].quote}"</p>
        <p style={authorStyle}>{quotes[quoteIndex].author}</p>
        <p style={whoStyle}>{quotes[quoteIndex].who}</p>
        <div style={{ ...loadingBarStyle, width: `${progress}%` }}></div>
        <p style={loadingTextStyle}>Loading...</p>
      </div>
    </div>
  );
};

const modalStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
};

const contentStyle = {
  backgroundColor: 'white',
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
  width: '80%',
  maxWidth: '600px',
};

const quoteStyle = {
  fontStyle: 'italic',
  marginBottom: '15px',
};

const authorStyle = {
  fontWeight: 'bold',
  marginBottom: '5px',
};

const whoStyle = {
  fontSize: '12px',
  textTransform: 'uppercase',
  marginBottom: '25px',
};

const loadingBarStyle = {
  height: '4px',
  backgroundColor: '#007bff',
  transition: 'width 0.1s linear',
};

const loadingTextStyle = {
  color: 'blue',
  marginTop: '25px',
};

export default LoadingModal;
