import React, { useState, useEffect } from 'react';
import '../css/QuestionDisplay.css';

const QuestionDisplay = ({
  question,
  currentIndex,
  totalQuestions,
  onNext,
  onPrevious,
  onScoreUpdate,
  onSaveSession,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    setSelectedOption(null);
    setShowAnswer(false);
  }, [question]);

  if (!question) {
    return <p>No question available</p>; 
  }

  const handleOptionChange = (index) => {
    setSelectedOption(index);
  };

  const handleSubmitAnswer = () => {
    setShowAnswer(true);

    const isCorrect = selectedOption === question.answer - 1;

    setAnswers([
      ...answers,
      {
        question: question.question,
        selectedOption,
        isCorrect,
        correctAnswer: question.answer - 1,
      },
    ]);

    if (isCorrect) {
      setScore((prevScore) => prevScore + 1);
    }

    onScoreUpdate(currentIndex + 1, isCorrect);
  };

  const handleSaveSession = () => {
    const sessionData = {
      questions: answers,
      score: score,
      totalQuestions: totalQuestions,
    };
    localStorage.setItem('quizSession', JSON.stringify(sessionData));
    if (onSaveSession) {
      onSaveSession();
    }
  };

  return (
    <div className="question-display-container">
      <div className="score-display">
        <p>{`Score: ${score}/${totalQuestions}`}</p>
      </div>

      <h3 className="question-index">Question {currentIndex + 1} of {totalQuestions}</h3>
      <h2 className="question-text">{question.question}</h2>

      <div className="options-container">
        <div className="options-group">
          {question.options.map((option, index) => (
            <label key={index} className={`custom-radio ${selectedOption === index ? 'selected' : ''}`}>
              <input
                type="radio"
                name={`question-${currentIndex}`}
                checked={selectedOption === index}
                onChange={() => handleOptionChange(index)}
                className="hidden-radio"
                disabled={showAnswer}
              />
              <span className="radio-mark"></span>
              {`${String.fromCharCode(65 + index)}: ${option}`} {/* options A, B, C */}
            </label>
          ))}
        </div>
      </div>

      {showAnswer && (
        <div className="answer-feedback">
          <p className={`answer-result ${selectedOption === question.answer - 1 ? 'correct' : 'incorrect'}`}>
            {selectedOption === question.answer - 1 ? 'Correct!' : 'Incorrect.'}
          </p>
          <p>
            <strong>Correct Answer:</strong> {`${String.fromCharCode(65 + (question.answer - 1))}. ${question.options[question.answer - 1]}`}
          </p>
          {question.reasoning && (
            <p className={`answer-reasoning ${selectedOption === question.answer - 1 ? 'correct' : 'incorrect'}`}>
              <strong>Explanation:</strong> {question.reasoning}
            </p>
          )}
        </div>
      )}

      {showAnswer && question.reference && (
        <div className="question-reference">
          <p><strong>Reference:</strong> {question.reference}</p>
        </div>
      )}

      <div className="navigation-and-submit">
        {!showAnswer && (
          <button
            onClick={handleSubmitAnswer}
            disabled={selectedOption === null}
            className="submit-button"
          >
            Submit
          </button>
        )}

        <div className="navigation-buttons">
          {currentIndex > 0 && (
            <button
              onClick={onPrevious}
              className="navigation-button previous-button"
            >
              Previous
            </button>
          )}
          {currentIndex < totalQuestions - 1 && (
            <button
              onClick={onNext}
              className="navigation-button next-button"
            >
              Next
            </button>
          )}
        </div>

        {currentIndex === totalQuestions - 1 && showAnswer && (
          <button
            onClick={handleSaveSession}
            className="navigation-button save-session-button"
          >
            Save Session
          </button>
        )}
      </div>
    </div>
  );
};

export default QuestionDisplay;
