import React, { useState } from 'react';
import LoadingModal from './LoadingModal';
import '../css/FileUpload.css';  

const FileUpload = ({ onUploadComplete }) => {
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);
  const [numQuestions, setNumQuestions] = useState(5);
  const [topics, setTopics] = useState([]);
  const [currentTopic, setCurrentTopic] = useState("");
  const [difficulty, setDifficulty] = useState("easy");
  const [uploading, setUploading] = useState(false);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    if (selectedFiles.length + files.length > 3) {
      setError('You can upload up to 3 files.');
      return;
    }
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    setError(null);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = Array.from(event.dataTransfer.files);
    if (droppedFiles.length + files.length > 3) {
      setError('You can upload up to 3 files.');
      return;
    }
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
    setError(null);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleUpload = () => {
    setError(null); 

    if (numQuestions <= 0) {
      setError('Number of questions must be greater than zero.');
      return;
    }

    if (numQuestions > 25) {
      setError('The number of questions cannot exceed 25.');
      return;
    }

    if (files.length === 0) {
      setError('Please select or drop files before uploading.');
      return;
    }

    const securityKey = process.env.REACT_APP_SECURITY_KEY || 'aGVsbG9ib29rYm9p';

    const formData = new FormData();
    files.forEach((file) => {
      formData.append('syllabus_files', file);
    });

    const chapterNames = topics.length > 0 ? topics.join(',') : 'all'; // Use 'all' if no topics
    const endpoint = `https://bookboiapi-959480042339.us-central1.run.app/create_session?security_key=${securityKey}&chapter_names=${chapterNames}&user_prompt=${difficulty}&number_of_questions=${numQuestions}`;

    const xhr = new XMLHttpRequest();
    xhr.open('POST', endpoint);

    setUploading(true);

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        setUploading(false);
        if (xhr.status === 200) {
          try {
            const response = JSON.parse(xhr.responseText);
            onUploadComplete(response);
          } catch (e) {
            setError('Failed to parse response.');
          }
        } else {
          setError('Error uploading files: ' + xhr.statusText);
        }
      }
    };

    xhr.send(formData);
  };

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const handleAddTopic = () => {
    if (currentTopic && !topics.includes(currentTopic)) {
      setTopics([...topics, currentTopic]);
      setCurrentTopic(""); 
    }
  };

  const handleRemoveTopic = (index) => {
    setTopics(topics.filter((_, i) => i !== index));
  };

  const isMobile = window.innerWidth <= 600;

  return (
    <div className="file-upload-container" style={{
      padding: isMobile ? '10px' : '20px',
      maxWidth: isMobile ? '100%' : '600px',
    }}>
      {uploading && <LoadingModal />}

      <div
        className="dropzone"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onClick={() => document.getElementById('fileInput').click()}
      >
        <p>Drag and drop your files here, or click to select files</p>
        <input
          id="fileInput"
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div>

      <label htmlFor="numQuestions">Number of Questions:</label>
      <input
        id="numQuestions"
        type="number"
        value={numQuestions}
        onChange={(e) => setNumQuestions(Math.min(Number(e.target.value), 25))}
        min="1"
        max="25"
      />

      <label htmlFor="topics">Topics (Optional):</label>
      <p className="instructions">Select specific topics to focus on. If no topics are selected, questions will be generated from the entire document.</p>
      <div className="topics-container">
        <input
          type="text"
          value={currentTopic}
          onChange={(e) => setCurrentTopic(e.target.value)}
          placeholder="Enter a topic"
        />
        <button onClick={handleAddTopic}>Add</button>
      </div>

      {topics.length > 0 && (
        <ul className="topics-list">
          {topics.map((topic, index) => (
            <li key={index}>
              {topic}
              <button onClick={() => handleRemoveTopic(index)} className="remove-topic-btn">
                X
              </button>
            </li>
          ))}
        </ul>
      )}

      <label>Difficulty Level:</label>
      <div className="difficulty-buttons">
        {["easy", "medium", "hard"].map((level) => (
          <button
            key={level}
            onClick={() => setDifficulty(level)}
            className={difficulty === level ? 'active' : ''}
          >
            {level.charAt(0).toUpperCase() + level.slice(1)}
          </button>
        ))}
      </div>

      <button onClick={handleUpload} className="upload-btn">
        {uploading ? 'Uploading...' : 'Upload and Generate MCQs'}
      </button>

      {error && <p className="error">{error}</p>}

      {files.length > 0 && (
        <div className="files-preview">
          <h3 className="files-preview-heading">Uploaded PDFs:</h3>
          {files.map((file, index) => (
            <div key={index} className="file-item">
              <div className="file-info">
                <span className="file-name">{file.name}</span>
                <button onClick={() => handleRemoveFile(index)} className="remove-file-btn">
                  X
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
